import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import * as auth from "../../../services/AuthService";
import * as api from "../../../services/ApiService";

function SidebarUser({ user }) {
  let navigate = useNavigate();
  const location = useLocation();

  // logout function
  const handleLogout = async () => {
    try {
      // Call function and API endpoint
      let res = await auth.logout({ user_id: user.id, token: user.token }, "user/logout");
      if (res) {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    } catch (error) {
      console.error("Unexpected error structure:", error);
    }
  };

  const getClassNames = (path) => {
    return location.pathname === path ? "menu-item menuitem-active" : "menu-item";
  };

  const [shortListedJobs, setShortListedJobs] = useState(0);
  const [appliedJobs, setAppliedJobs] = useState(0);
  const [messages, setMessages] = useState(0);
  const [checklists, setChecklists] = useState(0);

  useEffect(() => {
    const fetchSidebarMeta = async () => {
      try {
        const param = {
          user_id: user.id,
        };
        const result = await api.postMethod(param, "user/get-sidebar-data");
        if (result.status) {
          const apiData = result.data;

          setShortListedJobs(apiData.shortListedJobs);
          setAppliedJobs(apiData.appliedJobs);
          setMessages(apiData.messages);
          setChecklists(apiData.checklists);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSidebarMeta();

    // Set up the interval to call fetchSidebarMeta every 5 seconds
    const interval = setInterval(fetchSidebarMeta, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <ul className="menu">
      <li className="menu-title ">Navigation</li>
      <li className={getClassNames("/dashboard")}>
        <NavLink to="/dashboard" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-home" />
          </span>
          <span className="menu-text"> Dashboard </span>
        </NavLink>
      </li>

      <li className={getClassNames("/user/profile")}>
        <NavLink to="/user/profile" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-user-tie" />
          </span>
          <span className="menu-text " > My Profile </span>
        </NavLink>
      </li>
      <li className={getClassNames("/user/jobs")}>
        <NavLink to="/user/jobs" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-search" />
          </span>
          <span className="menu-text"> Search Jobs </span>
        </NavLink>
      </li>

      <li className={getClassNames("/user/applied-jobs")}>
        <NavLink to="/user/applied-jobs" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-briefcase" />
          </span>
          <span className="menu-text"> Applied Jobs </span>
          {appliedJobs > 0 && (
            <span class="badge badge-outline-primary badge-pill ms-auto">{appliedJobs}</span>
          )}
        </NavLink>
      </li>
      <li className={getClassNames("/user/saved-jobs")}>
        <NavLink to="/user/saved-jobs" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-bookmark" />
          </span>
          <span className="menu-text"> Saved Jobs </span>
          {shortListedJobs > 0 && (
            <span class="badge badge-outline-primary badge-pill ms-auto">{shortListedJobs}</span>
          )}
        </NavLink>
      </li>

      {/*
      <li className={getClassNames("/user/calendar")}>
        <NavLink to="/user/calendar" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-calendar" />
          </span>
          <span className="menu-text"> Calendar </span>
        </NavLink>
      </li>
      */}

      <li className={getClassNames("/user/messages")}>
        <NavLink to="/user/messages" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-comments" />
          </span>
          <span className="menu-text"> Messages </span>
          {messages > 0 && (
            <span class="badge badge-outline-primary badge-pill ms-auto">{messages}</span>
          )}
        </NavLink>
      </li>
      <li className={getClassNames("/user/documents")}>
        <NavLink to="/user/documents" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-folder-open" />
          </span>
          <span className="menu-text"> Documents </span>
        </NavLink>
      </li>

      {/* 
      <li className={getClassNames("/user/compliance-lists")}>
        <NavLink to="/user/compliance-lists" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-list" />
          </span>
          <span className="menu-text">Facility Compliance Lists </span>
        </NavLink>
      </li>
      */}

      <li className={getClassNames("/user/skill-checklists")}>
        <NavLink to="/user/skill-checklists" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-clipboard-check" />
          </span>
          <span className="menu-text"> Skill Checklists </span>
          {checklists > 0 && (
            <span class="badge badge-outline-primary badge-pill ms-auto">{checklists}</span>
          )}
        </NavLink>
      </li>
      <li className={getClassNames("/user/settings")}>
        <NavLink to="/user/settings" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-cog" />
          </span>
          <span className="menu-text"> Settings </span>
        </NavLink>
      </li>
      {/* 
      <li className={getClassNames("/user/subscription-plans")}>
        <NavLink to="/user/subscription-plans" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-box-open" />
          </span>
          <span className="menu-text"> Subscription Plans </span>
        </NavLink>
      </li>
      */}
      <li className="menu-item">
        <NavLink
          to="#"
          onClick={() => handleLogout()}
          className="menu-link"
          activeClassName="active"
        >
          <span className="menu-icon">
            <i className="fas fa-sign-out-alt" />
          </span>
          <span className="menu-text"> Logout </span>
        </NavLink>
      </li>
    </ul>
  );
}

export default SidebarUser;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "../Layout/Sidebar";
import ThemeSettings from "../Layout/ThemeSettings";
import * as api from "../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../utils/tokenUtils";
import { Link } from "react-router-dom";
import Spinner from "../Loader/Spinner";

function Dashboard() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  let fullName = user.name;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const scaleProgress = (value, maxValue) => {
    return (value / maxValue) * 100;
  };

  /*
  var today = new Date();
  var curHr = today.getHours();

  let strVal = "";
  if (curHr < 12) {
    strVal = "Good Morning";
  } else if (curHr < 18) {
    strVal = "Good Afternoon";
  } else {
    strVal = "Good Evening";
  }
  */

  // State for filter values
  const [filters, setFilters] = useState({
    job_title: "",
    state_id: 0,
    no_of_opening: "",
    profession_id: 0,
    specialty_id: 0,
    listing_type: "all",
  });

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      job_title: "",
      state_id: 0,
      no_of_opening: "",
      profession_id: 0,
      specialty_id: 0,
      job_type: "all",
    };
    setFilters(defaultFilters);
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams(filters).toString();
    navigate(`/user/jobs?${queryParams}`);
  };

  const [states, setStates] = useState([]);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [specialties, setspecialties] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchSpecialtyData = async () => {
      try {
        const apiData = await api.getMethod("get-specialties/" + filters.profession_id);

        setspecialties(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchSpecialtyData();
  }, [filters.profession_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [professions, setProfessions] = useState([]);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-professions");
        setProfessions(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    token: user.token,
    file_name: null,
  });

  const [greetingText, setGreetingText] = useState("");
  const [resumes, setResumes] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const [overallProgress, setOverallProgress] = useState({ class: "", value: 0 });
  const [personalInfoProgress, setPersonalInfoProgress] = useState({ class: "", value: 0 });
  const [resumeProgress, setResumeProgress] = useState({ class: "", value: 0 });
  const [workHistoryProgress, setWorkHistoryProgress] = useState({ class: "", value: 0 });
  const [educationalInfoProgress, seteducationalInfoProgress] = useState({ class: "", value: 0 });
  const [referencesInfoProgress, setreferencesInfoProgress] = useState({ class: "", value: 0 });
  /*const [skillProgress, setSkillProgress] = useState({ class: "", value: 0 });*/
  const [jobPreferenceProgress, setJobPreferenceProgress] = useState({ class: "", value: 0 });
  const [skillChecklistProgress, setSkillChecklistProgress] = useState({ class: "", value: 0 });

  const [resumeBuilderProgress, setResumeBuilderProgress] = useState({ class: "", value: 0 });
  const [submissionFileBuilderProgress, setSubmissionFileBuilderProgress] = useState({
    class: "",
    value: 0,
  });

  const [appliedJobs, setAppliedJob] = useState({});
  const [appliedJobLoading, setAppliedJobLoading] = useState(true);
  const [noAppliedJobRecordsFound, setNoAppliedJobRecordsFound] = useState(false);

  const fetchDashboardMeta = async () => {
    try {
      const param = {
        user_id: user.id,
      };
      const result = await api.postMethod(param, "user/get-dashboard-data");
      if (result.status) {
        const apiData = result.data;

        setGreetingText(apiData.greetingText);

        const apiDataObj = apiData.resumes.reduce((acc, currentValue, index) => {
          acc[index] = currentValue;
          return acc;
        }, {});

        setResumes(apiDataObj);
        setNoRecordsFound(false);
        setLoading(false);
        // Check if data array is empty
        if (apiData.resumes.length === 0) {
          setNoRecordsFound(true);
        }

        const apiAppliedJobObj = apiData.applied_job.reduce((acc, currentValue, index) => {
          acc[index] = currentValue;
          return acc;
        }, {});

        setAppliedJob(apiAppliedJobObj);

        setAppliedJobLoading(false);
        // Check if data array is empty
        if (apiData.applied_job.length === 0) {
          setNoAppliedJobRecordsFound(true);
        }

        setResumeBuilderProgress({
          ...overallProgress,
          class: calProgressClass(apiData.profile_statistics.resume_builder_progress),
          value: apiData.profile_statistics.resume_builder_progress,
        });

        setSubmissionFileBuilderProgress({
          ...overallProgress,
          class: calProgressClass(apiData.profile_statistics.submission_file_builder_progress),
          value: apiData.profile_statistics.submission_file_builder_progress,
        });

        setOverallProgress({
          ...overallProgress,
          class: calProgressClass(apiData.profile_statistics.overall_progress),
          value: apiData.profile_statistics.overall_progress,
        });

        setPersonalInfoProgress({
          ...personalInfoProgress,
          class: calProgressClass(apiData.profile_statistics.personal_info_progress),
          value: apiData.profile_statistics.personal_info_progress,
        });

        setResumeProgress({
          ...resumeProgress,
          class: calProgressClass(apiData.profile_statistics.resume_progress),
          value: apiData.profile_statistics.resume_progress,
        });

        setWorkHistoryProgress({
          ...workHistoryProgress,
          class: calProgressClass(apiData.profile_statistics.work_history_progress),
          value: apiData.profile_statistics.work_history_progress,
        });

        seteducationalInfoProgress({
          ...educationalInfoProgress,
          class: calProgressClass(apiData.profile_statistics.education_progress),
          value: apiData.profile_statistics.education_progress,
        });

        setreferencesInfoProgress({
          ...referencesInfoProgress,
          class: calProgressClass(apiData.profile_statistics.references_progress),
          value: apiData.profile_statistics.references_progress,
        });

        /*
        setSkillProgress({
          ...skillProgress,
          class: calProgressClass(apiData.profile_statistics.skill_progress),
          value: apiData.profile_statistics.skill_progress,
        });
        */

        setJobPreferenceProgress({
          ...jobPreferenceProgress,
          class: calProgressClass(apiData.profile_statistics.job_preference_progress),
          value: apiData.profile_statistics.job_preference_progress,
        });

        setSkillChecklistProgress({
          ...skillChecklistProgress,
          class: calProgressClass(apiData.profile_statistics.skill_checklist_progress),
          value: apiData.profile_statistics.skill_checklist_progress,
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setAppliedJobLoading(false);
      setIsLoading(false);
    }
  };

  const calProgressClass = (percentage) => {
    if (percentage >= 75) return "bg-success";
    else if (percentage >= 50) return "bg-warning";
    else return "bg-danger";
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDashboardMeta();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleFileChange = (e) => {
    setformData((prevState) => ({
      ...prevState,
      file_name: e.target.files[0], // Update image field with the selected file
    }));
    /*
    setformData({
      ...formData,
      file_name: e.target.files[0], // Update image field with the selected file
    });
    */
    uploadResume(e.target.files[0]);
  };

  const uploadResume = async (file) => {
    setIsLoading(true);
    let postData = { ...formData };
    postData.file_name = file;
    try {
      const result = await api.postFileMethod(postData, "user/upload-resume");
      if (result.status) {
        fetchDashboardMeta();
        document.getElementById("file_name").value = "";
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Unexpected error structure:", error);
    }
  };

  const [employmentTypes, setEmploymentTypes] = useState([]);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-employment-types");
        setEmploymentTypes(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title">
                      {greetingText && greetingText + ", " + fullName} &nbsp;
                    </h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card custom-box-shadow">
                    <form onSubmit={handleFilterSubmit}>
                      <div className="card-body">
                        <h4 class="header-title mb-3">Find Your Ideal Job</h4>

                        <div className="row">
                          <div className="col-md-3 mb-2">
                            <label htmlFor="job_title" className="form-label">
                              Keyword
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="job_title"
                              name="job_title"
                              value={filters.job_title}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-md-3 mb-2">
                            <label htmlFor="state_id" className="form-label">
                              Location
                            </label>
                            <select
                              className="form-control"
                              id="state_id"
                              onChange={handleInputChange}
                              name="state_id"
                              value={filters.state_id}
                            >
                              <option value="" key="">
                                Please select
                              </option>
                              {/* Render the states */}
                              {states &&
                                states.length > 0 &&
                                states.map((state) => (
                                  <option key={state.id} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-md-3 mb-2">
                            <label htmlFor="no_of_opening" className="form-label">
                              No. of Openings
                            </label>
                            <select
                              className="form-control"
                              id="no_of_opening"
                              onChange={handleInputChange}
                              name="no_of_opening"
                              value={filters.no_of_opening}
                            >
                              <option value="">Please select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="3+">3+</option>
                            </select>
                          </div>
                          <div className="col-md-3 mb-2">
                            <label htmlFor="profession_id" className="form-label">
                              Professions
                            </label>
                            <select
                              className="form-control"
                              id="profession_id"
                              onChange={handleInputChange}
                              name="profession_id"
                              value={filters.profession_id}
                            >
                              <option value="0" key="0">
                                Please select
                              </option>
                              {/* Render the professions */}
                              {professions &&
                                professions.length > 0 &&
                                professions.map((profession) => (
                                  <option key={profession.id} value={profession.id}>
                                    {profession.profession}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-md-3 mb-2">
                            <label htmlFor="specialty_id" className="form-label">
                              Specialty
                            </label>
                            <select
                              className="form-control"
                              id="specialty_id"
                              onChange={handleInputChange}
                              name="specialty_id"
                              value={filters.specialty_id}
                            >
                              <option value="" key="">
                                Please select
                              </option>
                              {/* Render the specialties */}
                              {specialties &&
                                specialties.length > 0 &&
                                specialties.map((specialty) => (
                                  <option key={specialty.id} value={specialty.id}>
                                    {specialty.specialty}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <div className="mb-2 col-md-3">
                            <label htmlFor="job_type" className="form-label">
                              Job Type
                            </label>
                            <select
                              id="job_type"
                              className="form-control"
                              name="job_type"
                              value={filters.job_type}
                              onChange={handleInputChange}
                            >
                              <option value="" key="">
                                Please select
                              </option>
                              {/* Render the professions */}
                              {employmentTypes.map((emp) => (
                                <option key={emp.id} value={emp.id}>
                                  {emp.title}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-3 mb-2">
                            <label htmlFor="listing_type" className="form-label">
                              Job Selection
                            </label>
                            <select
                              className="form-control"
                              id="listing_type"
                              onChange={handleInputChange}
                              name="listing_type"
                              value={filters.listing_type}
                            >
                              <option value="all">All Jobs</option>
                              <option value="saved">Saved Jobs</option>
                            </select>
                          </div>
                          <div className="mb-2 col-md-3">
                            <label htmlFor="order_by" className="form-label">
                              Order By
                            </label>
                            <select
                              className="form-control"
                              id="order_by"
                              name="order_by"
                              value={filters.order_by}
                              onChange={handleInputChange}
                            >
                              <option value="default" key="default">
                                Default
                              </option>
                              <option value="highest" key="highest">
                                Highest Pay First
                              </option>
                              <option value="lowest" key="lowest">
                                Lowest Pay First
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer border-top">
                        <div className="row">
                          <div className="col-md-1">
                            <input
                              type="submit"
                              value="Submit"
                              className="btn btn-primary btn-block w-100"
                            />
                          </div>

                          <div className="col-md-1">
                            <button
                              type="button"
                              className="btn btn-secondary btn-block w-100"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mb-3">Resume</h4>
                      <form
                        className="dropzone dz-clickable p-0 custom-file-upload-container position-relative"
                        id="resume"
                      >
                        <div className="dz-message needsclick">
                          <i className="h3 text-muted dripicons-cloud-upload" />
                          <h4>Drop or Click to Upload New Resume</h4>
                        </div>

                        <input
                          type="file"
                          name="file_name"
                          id="file_name"
                          onChange={handleFileChange}
                          accept="*"
                        />
                      </form>
                      {/* Preview */}
                      <div className="dropzone-previews mt-3" id="file-previews" />

                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <>
                          {noRecordsFound ? (
                            <p>No resume uploaded yet</p>
                          ) : (
                            <>
                              {Object.entries(resumes).map(([key, value]) => (
                                <div className="card mb-0 mt-2 shadow-none border" key={value.id}>
                                  <div className="p-2">
                                    <div className="row align-items-center">
                                      <div className="col-auto">
                                        <div className="avatar-sm">
                                          <span className="avatar-title badge-soft-primary text-primary rounded">
                                            {value.file_type}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col ps-0">
                                        <span className="text-muted fw-bold">{value.title}</span>
                                        <p className="mb-0 font-12">
                                          {value.file_size} | {formatDate(value.created_at)}
                                          {", "}
                                          {formatTime(value.created_at)}
                                        </p>
                                      </div>
                                      <div className="col-auto">
                                        <a
                                          href={value.dir_path}
                                          download
                                          target="_blank"
                                          rel="noreferrer"
                                          className="btn btn-link font-16 text-muted"
                                          data-tooltip-id="mt-4"
                                          data-tooltip-content="Download Document"
                                        >
                                          <i className="dripicons-download" />
                                        </a>
                                        <Tooltip id="mt-4"></Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="header-title mb-1">Profile</h4>
                      <p className="sub-header">
                        Allow us to walk you through each step so you can create a comprehensive
                        profile and get hired for your future position.
                      </p>
                      <h5 className="header-title">
                        Profile Strength : <strong>{overallProgress.value}%</strong>
                      </h5>
                      <div className="progress mb-3">
                        <div
                          className={`progress-bar ${overallProgress.class}`}
                          role="progressbar"
                          style={{ width: `${overallProgress.value}%` }}
                          aria-valuenow={overallProgress.value}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>

                      <div className="card border overflow-hidden">
                        <div className="card-header border-bottom bg-light ">
                          <p className="mb-2 fw-semibold">
                            Resume Builder
                            <span className="float-end">{resumeBuilderProgress.value}%</span>
                          </p>
                          <div className="progress mb-1" style={{ height: 10 }}>
                            <div
                              className={`progress-bar ${resumeBuilderProgress.class}`}
                              role="progressbar"
                              style={{ width: `${resumeBuilderProgress.value}%` }}
                              aria-valuenow={resumeBuilderProgress.value}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <ul className="list-group border-0">
                            <li className="list-group-item border-bottom">
                              <p className="mb-2 fw-semibold">
                                Job Preference{" "}
                                {jobPreferenceProgress.value < 100 && (
                                  <button
                                    className="btn btn-rounded btn-xs btn-info"
                                    onClick={() =>
                                      navigate(`/user/profile?ref=submission-file`, {
                                        state: { tab: "job_preference" },
                                      })
                                    }
                                  >
                                    Complete Now
                                  </button>
                                )}
                                <span className="float-end">{jobPreferenceProgress.value}%</span>
                              </p>
                              <div className="progress mb-1" style={{ height: 7 }}>
                                <div
                                  className={`progress-bar ${jobPreferenceProgress.class}`}
                                  role="progressbar"
                                  style={{ width: `${jobPreferenceProgress.value}%` }}
                                  aria-valuenow={jobPreferenceProgress.value}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </li>
                            <li className="list-group-item  border-bottom">
                              <p className="mb-2 fw-semibold">
                                Personal Information{" "}
                                {personalInfoProgress.value < 100 && (
                                  <button
                                    className="btn btn-rounded btn-xs btn-info"
                                    onClick={() =>
                                      navigate(`/user/profile?ref=submission-file`, {
                                        state: { tab: "personal_info" },
                                      })
                                    }
                                  >
                                    Complete Now
                                  </button>
                                )}
                                <span className="float-end">{personalInfoProgress.value}%</span>
                              </p>
                              <div className="progress mb-1" style={{ height: 7 }}>
                                <div
                                  className={`progress-bar ${personalInfoProgress.class}`}
                                  role="progressbar"
                                  style={{ width: `${personalInfoProgress.value}%` }}
                                  aria-valuenow={personalInfoProgress.value}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </li>

                            <li className="list-group-item border-bottom">
                              <p className="mb-2 fw-semibold">
                                Work History{" "}
                                {workHistoryProgress.value < 100 && (
                                  <button
                                    className="btn btn-rounded btn-xs btn-info"
                                    onClick={() =>
                                      navigate(`/user/profile?ref=submission-file`, {
                                        state: { tab: "work_history" },
                                      })
                                    }
                                  >
                                    Complete Now
                                  </button>
                                )}
                                <span className="float-end">{workHistoryProgress.value}%</span>
                              </p>
                              <div className="progress mb-1" style={{ height: 7 }}>
                                <div
                                  className={`progress-bar ${workHistoryProgress.class}`}
                                  role="progressbar"
                                  style={{ width: `${workHistoryProgress.value}%` }}
                                  aria-valuenow={workHistoryProgress.value}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </li>
                            <li className="list-group-item">
                              <p className="mb-2 fw-semibold">
                                Educational Information{" "}
                                {educationalInfoProgress.value < 100 && (
                                  <button
                                    className="btn btn-rounded btn-xs btn-info"
                                    onClick={() =>
                                      navigate(`/user/profile?ref=submission-file`, {
                                        state: { tab: "educational_info" },
                                      })
                                    }
                                  >
                                    Complete Now
                                  </button>
                                )}
                                <span className="float-end">{educationalInfoProgress.value}%</span>
                              </p>
                              <div className="progress mb-1" style={{ height: 7 }}>
                                <div
                                  className={`progress-bar ${educationalInfoProgress.class}`}
                                  role="progressbar"
                                  style={{ width: `${educationalInfoProgress.value}%` }}
                                  aria-valuenow={educationalInfoProgress.value}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="card border overflow-hidden">
                        <div className="card-header border-bottom bg-light ">
                          <p className="mb-2 fw-semibold">
                            Submission File Builder
                            <span className="float-end">
                              {submissionFileBuilderProgress.value}%
                            </span>
                          </p>
                          <div className="progress mb-1" style={{ height: 10 }}>
                            <div
                              className={`progress-bar ${submissionFileBuilderProgress.class}`}
                              role="progressbar"
                              style={{ width: `${submissionFileBuilderProgress.value}%` }}
                              aria-valuenow={submissionFileBuilderProgress.value}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <ul className="list-group border-0">
                            <li className="list-group-item">
                              <p className="mb-2 fw-semibold">
                                Skill Checklist{" "}
                                {skillChecklistProgress.value < 100 && (
                                  <button
                                    className="btn btn-rounded btn-xs btn-info"
                                    onClick={() => navigate(`/user/skill-checklists`)}
                                  >
                                    Complete Now
                                  </button>
                                )}
                                <span className="float-end">{skillChecklistProgress.value}%</span>
                              </p>
                              <div className="progress mb-1" style={{ height: 7 }}>
                                <div
                                  className={`progress-bar ${skillChecklistProgress.class}`}
                                  role="progressbar"
                                  style={{ width: `${skillChecklistProgress.value}%` }}
                                  aria-valuenow={skillChecklistProgress.value}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </li>

                            <li className="list-group-item">
                              <p className="mb-2 fw-semibold">
                                References{" "}
                                {referencesInfoProgress.value < 100 && (
                                  <button
                                    className="btn btn-rounded btn-xs btn-info"
                                    onClick={() =>
                                      navigate(`/user/profile?ref=submission-file`, {
                                        state: { tab: "references" },
                                      })
                                    }
                                  >
                                    Complete Now
                                  </button>
                                )}
                                <span className="float-end">{referencesInfoProgress.value}%</span>
                              </p>
                              <div className="progress mb-1" style={{ height: 7 }}>
                                <div
                                  className={`progress-bar ${referencesInfoProgress.class}`}
                                  role="progressbar"
                                  style={{ width: `${referencesInfoProgress.value}%` }}
                                  aria-valuenow={referencesInfoProgress.value}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="card border mb-0 overflow-hidden">
                        <div className="card-header border-bottom bg-light ">
                          <p className="mb-0 fw-semibold">Additional Documents</p>
                        </div>
                        <div className="card-body p-0">
                          <ul className="list-group border-0">
                            <li className="list-group-item">
                              <p className="mb-2 fw-semibold">
                                Resume <span className="float-end">{resumeProgress.value}%</span>
                              </p>
                              <div className="progress mb-1" style={{ height: 7 }}>
                                <div
                                  className={`progress-bar ${resumeProgress.class}`}
                                  role="progressbar"
                                  style={{ width: `${resumeProgress.value}%` }}
                                  aria-valuenow={resumeProgress.value}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body custom-box-shadow">
                      <h4 className="header-title mb-3">Recent Applied Jobs</h4>
                      <div className="table-responsive">
                        <table className="table table-centered m-0">
                          <thead className="table-light">
                            <tr>
                              <th>Job Title</th>
                              <th>Applied On</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {appliedJobLoading ? (
                              <tr>
                                <td colSpan="3">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noAppliedJobRecordsFound ? (
                                  <tr>
                                    <td colSpan="3">No resume found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(appliedJobs).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>
                                          <h5 className="m-0 font-weight-normal">
                                            <Link to={`/user/job/` + value.unique_id} className="">
                                              {value.title}{" "}
                                            </Link>
                                            <span className="mb-0 text-muted">
                                              <small>(#{value.unique_id})</small>
                                            </span>
                                          </h5>
                                          <p className="mb-0 text-muted">
                                            <small>
                                              {value.state_code !== null && value.city_name !== null
                                                ? value.city_name + ", " + value.state_code
                                                : value.state_code !== null
                                                ? value.state_code
                                                : value.city_name !== null
                                                ? value.city_name
                                                : ""}
                                            </small>
                                          </p>
                                        </td>
                                        <td>
                                          {formatDate(value.created_at)}{" "}
                                          <small className="text-muted">
                                            {formatTime(value.created_at)}
                                          </small>
                                        </td>
                                        <td>
                                          {value.status == 1 && (
                                            <label className="badge badge-soft-primary">
                                              Applied
                                            </label>
                                          )}
                                          {value.status == 2 && (
                                            <label className="badge badge-soft-info">
                                              Shortlisted
                                            </label>
                                          )}
                                          {value.status == 3 && (
                                            <label className="badge badge-soft-warning">
                                              Submitted
                                            </label>
                                          )}
                                          {value.status == 4 && (
                                            <label className="badge badge-soft-warning">
                                              Interviewing
                                            </label>
                                          )}
                                          {value.status == 5 && (
                                            <label className="badge badge-soft-secondary">
                                              Offered
                                            </label>
                                          )}
                                          {value.status == 6 && (
                                            <label className="badge badge-soft-success">
                                              Hired
                                            </label>
                                          )}
                                          {value.status == 7 && (
                                            <label className="badge badge-soft-danger">N/A</label>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default Dashboard;

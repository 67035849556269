import React, { useState, useEffect } from "react";
import * as profile from "../../../../services/ProfileService";
import * as Yup from "yup";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { toast } from "react-toastify";
import Spinner from "../../../Loader/Spinner";
import { Tooltip } from "react-tooltip";

function JobPreference({ states, employmentTypes, jobShifts, fetchProfileData }) {
  const user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setformData] = useState({
    user_id: user.id,
    token: user.token,
    available_start_date: null,
    profession_id: null,
    specialty_id: null,
    employement_types: [],
    shifts: [],
    desired_state_ids: [],
    total_experience: "",
  });

  const [professions, setProfessions] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-professions");
        setProfessions(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [specialties, setspecialties] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchSpecialtyData = async () => {
      try {
        const apiData = await api.getMethod("get-specialties/" + formData.profession_id);

        setspecialties(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchSpecialtyData();
  }, [formData.profession_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object().shape({
    employement_types: Yup.array().min(1, "Select at least one employment type"),
    shifts: Yup.array().min(1, "Select at least one shift"),
    desired_state_ids: Yup.array().min(1, "Select at least one state"),
    profession_id: Yup.string().required("Profession is Required"),
    specialty_id: Yup.string().required("Specialty is Required"),
    /*available_start_date: Yup.string().required("Available Start Date is Required"),*/
    total_experience: Yup.string().required("Please Select Experience"),
  });

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const [selectAll, setSelectAll] = useState(false);

  // Handle Select All Checkbox
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;

    if (checked) {
      // Select all state ids
      const allStateIds = states.map((state) => state.id);
      //setformData({ ...formData, desired_state_ids: allStateIds });
      setformData({ ...formData, desired_state_ids: [...allStateIds, "all"] });
      setSelectAll(true);
    } else {
      setSelectAll(false);
      // Deselect all
      setformData({ ...formData, desired_state_ids: [] });
    }
  };

  const handleEtCheckboxChange = (event) => {
    const { name, value, checked } = event.target;

    setformData((prevValues) => {
      const newValue = parseInt(value);
      const updatedTypes = checked
        ? [...prevValues.employement_types, newValue]
        : prevValues.employement_types.filter((item) => item !== newValue);

      validateFields(name, updatedTypes);

      return {
        ...prevValues,
        employement_types: updatedTypes,
      };
    });
  };

  const handleShiftCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    const newValue = parseInt(value);
    setformData((prevValues) => {
      const updatedShifts = checked
        ? [...prevValues.shifts, newValue]
        : prevValues.shifts.filter((item) => item !== newValue);

      validateFields(name, updatedShifts);

      return {
        ...prevValues,
        shifts: updatedShifts,
      };
    });
  };

  // Handle individual select change
  const handleSelectChange = (e) => {
    const { name, value, checked } = e.target;

    const options = Array.from(e.target.selectedOptions, (option) => option.value);

    // Remove "all" if user deselects states individually
    const filteredOptions = options.filter((item) => item !== "all");

    setformData({ ...formData, desired_state_ids: filteredOptions });

    // Uncheck Select All if not all states are selected
    if (filteredOptions.length == states.length) {
      setSelectAll(true);
      setformData({ ...formData, desired_state_ids: [...filteredOptions, "all"] });
    } else setSelectAll(false);
    validateFields(name, filteredOptions);
  };

  useEffect(() => {
    setSelectAll(false);
    if (states.length > 0 && formData.desired_state_ids.length >= states.length) {
      setSelectAll(true); // If all states are selected
    }
  }, [states, formData.desired_state_ids]);

  const fetchJobPreferenceData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-job-preference");
      setformData((prevValues) => ({
        ...prevValues,
        employement_types: apiData.data.employement_types || [],
        shifts: apiData.data.shifts || [],
        desired_state_ids: apiData.data.desired_state_ids || [],

        profession_id: apiData.data.profession_id || null,
        specialty_id: apiData.data.specialty_id || null,
        available_start_date: apiData.data.available_start_date || null,

        total_experience: apiData.data.total_experience,
      }));

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchJobPreferenceData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields before submission
    let isValid = true;
    try {
      await validationSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      isValid = false;
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }

    if (!isValid) return;

    try {
      const result = await api.postMethod(formData, "user/update-job-preference");
      if (result) {
        toast.success(result.message);
        fetchJobPreferenceData();
        fetchProfileData();
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label htmlFor="profession_id" className="form-label">
            Profession *
          </label>
          <select
            id="profession_id"
            className="form-control"
            name="profession_id"
            value={formData.profession_id !== null ? formData.profession_id : ""}
            onChange={handleChange}
          >
            <option value="" key="">
              Please Select
            </option>
            {/* Render the professions */}
            {professions.map((profession) => (
              <option key={profession.id} value={profession.id}>
                {profession.profession}
              </option>
            ))}
          </select>
          {errors.profession_id && <span className="error">{errors.profession_id}</span>}
        </div>
        <div className="form-group mb-3 col-md-4">
          <label htmlFor="specialty_id" className="form-label">
            Specialty *
          </label>
          <select
            id="specialty_id"
            className="form-control"
            name="specialty_id"
            value={formData.specialty_id !== null ? formData.specialty_id : ""}
            onChange={handleChange}
          >
            <option value="" key="">
              Please Select
            </option>

            {/* Render the specialties */}
            {specialties.map((specialty) => (
              <option key={specialty.id} value={specialty.id}>
                {specialty.specialty}
              </option>
            ))}
          </select>
          {errors.specialty_id && <span className="error">{errors.specialty_id}</span>}
        </div>

        <div className="col-md-4">
          <div className="mb-0">
            <label htmlFor="available_start_date" className="form-label">
              Available Start Date
            </label>
            <input
              className="form-control"
              type="date"
              name="available_start_date"
              id="available_start_date"
              defaultValue={
                formData.available_start_date !== null ? formData.available_start_date : ""
              }
              onChange={handleChange}
            />
            {/*errors.available_start_date && (
              <span className="error">{errors.available_start_date}</span>
            )*/}
          </div>
        </div>

        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="total_experience" className="form-label">
              Total Years of Experience
            </label>
            <select
              className="form-control"
              id="total_experience"
              name="total_experience"
              onChange={handleChange}
              value={formData.total_experience !== null ? formData.total_experience : ""}
            >
              <option value="" key="">
                0 Year(s)
              </option>
              {Array.from(Array(15), (e, i) => {
                return (
                  <option value={i + 1} key={i + 1}>
                    {i + 1} Year(s)
                  </option>
                );
              })}
              <option value="15+">15+ Year(s)</option>
            </select>
            {errors.total_experience && <span className="error">{errors.total_experience}</span>}
          </div>
        </div>

        <div className="col-md-2">
          <div className="mb-0">
            <label htmlFor="employment_type_id" className="form-label">
              Employment Type
            </label>
            <br />
            {employmentTypes &&
              employmentTypes.length > 0 &&
              employmentTypes.map((etype) => (
                <label key={etype.id} htmlFor={`et_${etype.id}`} className="me-2 d-block">
                  <input
                    type="checkbox"
                    name="employement_types"
                    value={etype.id}
                    id={`et_${etype.id}`}
                    onChange={handleEtCheckboxChange}
                    checked={formData.employement_types.includes(etype.id)}
                  />{" "}
                  {etype.title}
                </label>
              ))}
            {errors.employement_types && <span className="error">{errors.employement_types}</span>}
          </div>
        </div>

        <div className="col-md-2">
          <div className="mb-0">
            <label htmlFor="shift" className="form-label">
              Shift
            </label>
            <br />
            {jobShifts &&
              jobShifts.length > 0 &&
              jobShifts.map((shift) => (
                <label key={shift.id} htmlFor={`shift_${shift.id}`} className="me-2 d-block">
                  <input
                    type="checkbox"
                    name="shifts"
                    value={shift.id}
                    id={`shift_${shift.id}`}
                    onChange={handleShiftCheckboxChange}
                    checked={formData.shifts.includes(shift.id)}
                  />{" "}
                  {shift.title}
                </label>
              ))}
            {errors.shifts && <span className="error">{errors.shifts}</span>}
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-2">
            <label htmlFor="desired_state_ids" className="form-label w-100">
              Where would you like to work?
              <br />
              <small className="text-muted">(Ctrl+Click to Select Multiple)</small>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
                className="float-end mt-n2"
                data-tooltip-id="mt-3"
                data-tooltip-content={selectAll ? "Unselect All" : "Select All"}
              />
              <Tooltip id="mt-3"></Tooltip>
            </label>

            <select
              className="form-control"
              name="desired_state_ids"
              id="desired_state_ids"
              multiple
              value={formData.desired_state_ids !== null ? formData.desired_state_ids : []}
              onChange={handleSelectChange}
            >
              {states &&
                states.length > 0 &&
                states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
            </select>
            {errors.desired_state_ids && <span className="error">{errors.desired_state_ids}</span>}
          </div>
        </div>
      </div>

      <div className="text-end">
        <button type="submit" className="btn btn-primary waves-effect waves-light mt-2">
          Save
        </button>
      </div>

      {isLoading === true && <Spinner />}
    </form>
  );
}

export default JobPreference;
